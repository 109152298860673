import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './assetComponent.scss'
import ImageBlock from "../acimage";
import VideoBlock from "../VideoBlock/videoBlock";
import { getRelevantImageProp, AssetTypes } from "../../assets/js/utils"
import { Modal, Button } from 'react-bootstrap'


function VideoModal(props) {
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="video-modal"
            centered
            className="video-modal"
        >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
                <VideoBlock url={props.url} />
            </Modal.Body>
        </Modal>
    );
}

export default function AssetComponent(props) {
    const [modalShow, setModalShow] = useState(false);
    const { video, category, assetDescription, image, DEVICE_VIEW } = props;
    return (
        <div className={`${"embeded-items-section"} has-${category.toLowerCase()}`}>
            <div className="container">
                {image && <div className="embeded-item">
                    <figure>
                        <ImageBlock image={getRelevantImageProp(props, DEVICE_VIEW.IsMobile)} className="object-embed" />
                        {assetDescription && <figcaption>{assetDescription}</figcaption>}
                    </figure>
                    {category === AssetTypes['Video'] && <Button variant="play-icon" onClick={() => setModalShow(true)} >
                        <i className="icon icon-play"></i></Button>}
                </div>}
                <VideoModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    url={video}
                />
            </div>
        </div>
    )

}

AssetComponent.propTypes = {
    video: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.object,
    image: PropTypes.object,
    DEVICE_VIEW: PropTypes.object
}